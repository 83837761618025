import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EIntegrationTypes } from '@feathr/blackbox';
import { Section } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useGoogleAdsPermissions } from '@feathr/extender/hooks';
import { useAccount, useFlags } from '@feathr/extender/state';

import IntegrationsCard from './IntegrationsCard';

import * as styles from './IntegrationsDashboardPage.css';

import blackbaudLogo from 'images/integrations/blackbaud.png';
import googleAdsLogo from 'images/integrations/google_ads.png';
import iMISLogo from 'images/integrations/iMIS.png';
import bizzaboLogo from 'images/zapier_brands/blizzabo.png';
import bloomerang from 'images/zapier_brands/bloomerang.png';
import cventLogo from 'images/zapier_brands/cvent.png';
import eventbriteLogo from 'images/zapier_brands/eventbrite.png';
import hopinLogo from 'images/zapier_brands/hopin.png';
import hubspotLogo from 'images/zapier_brands/hubspot.png';
import mailchimpLogo from 'images/zapier_brands/mailchimp.png';
import metaLogo from 'images/zapier_brands/meta.png';
import realMagnet from 'images/zapier_brands/real_magnet.png';
import salesforceLogo from 'images/zapier_brands/salesforce.png';
import wordpressLogo from 'images/zapier_brands/wordpress.png';
import zapierLogo from 'images/zapier_brands/zapier.png';

export interface IIntegrationCardProps {
  logo?: string;
  name: string;
  url: string;
}

export interface IZapierIntegrationsCardProps extends IIntegrationCardProps {
  helpDesk: string;
  integrationUrl: string;
}

const zapierIntegrations: IZapierIntegrationsCardProps[] = [
  {
    name: 'Cvent',
    url: 'https://zapier.com/apps/cvent/integrations',
    integrationUrl: 'https://zapier.com/apps/feathr/integrations/cvent',
    logo: cventLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460251374103-How-to-Integrate-Feathr-and-Cvent',
  },
  {
    name: 'Bizzabo',
    url: 'https://zapier.com/apps/bizzabo/integrations',
    integrationUrl: 'https://zapier.com/apps/feathr/integrations/bizzabo',
    logo: bizzaboLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460349533719-How-to-Integrate-Feathr-and-Bizzabo',
  },
  {
    name: 'Bloomerang',
    url: 'https://zapier.com/apps/bloomerang/integrations',
    integrationUrl: 'https://zapier.com/apps/feathr/integrations/bloomerang',
    logo: bloomerang,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460449117719-How-to-Integrate-Feathr-and-Bloomerang',
  },
  {
    name: 'Real Magnet',
    url: 'https://zapier.com/apps/real-magnet/integrations',
    integrationUrl: 'https://zapier.com/apps/real-feathr/integrations/magnet',
    logo: realMagnet,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460452674583-How-to-Integrate-Feathr-and-Realmagnet',
  },
  {
    name: 'Eventbrite',
    url: 'https://zapier.com/apps/eventbrite/integrations',
    integrationUrl: 'https://zapier.com/apps/feathr/integrations/eventbrite',
    logo: eventbriteLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460511822359-How-to-Integrate-Feathr-and-Eventbrite',
  },
  {
    name: 'HubSpot',
    url: 'https://zapier.com/apps/hubspot/integrations',
    integrationUrl: 'https://zapier.com/apps/feathr/integrations/hubspot',
    logo: hubspotLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9455026528535-How-to-Integrate-Feathr-and-Hubspot',
  },
  {
    name: 'Wordpress',
    url: 'https://zapier.com/apps/wordpress/integrations',
    integrationUrl: 'https://zapier.com/apps/feathr/integrations/wordpress',
    logo: wordpressLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460665698455-How-to-Integrate-Feathr-and-Wordpress',
  },
  {
    name: 'Mailchimp',
    url: 'https://zapier.com/apps/mailchimp/integrations',
    integrationUrl: 'https://zapier.com/apps/feathr/integrations/mailchimp',
    logo: mailchimpLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9455019907223-How-to-Integrate-Feathr-and-Mailchimp',
  },
  {
    name: 'Hopin',
    url: 'https://zapier.com/apps/hopin/integrations',
    integrationUrl: 'https://zapier.com/apps/feathr/integrations/hopin',
    logo: hopinLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460711843863-How-to-Integrate-Feathr-and-Hopin',
  },
  {
    name: 'Salesforce',
    url: 'https://zapier.com/apps/salesforce/integrations',
    integrationUrl: 'https://zapier.com/apps/feathr/integrations/salesforce',
    logo: salesforceLogo,
    helpDesk:
      'https://help.feathr.co/hc/en-us/articles/9460248409879-How-to-Integrate-Feathr-and-Salesforce',
  },
];

const feathrZapierIntegration: IIntegrationCardProps = {
  name: 'All Integrations',
  url: 'https://zapier.com/apps/feathr',
  logo: zapierLogo,
};

const metaIntegration: IIntegrationCardProps = {
  logo: metaLogo,
  name: 'Meta',
  url: '/settings/integrations/meta',
};

const googleIntegration: IIntegrationCardProps = {
  logo: googleAdsLogo,
  name: 'Google Ads',
  url: '/settings/integrations/google-ads',
};

const formImplementation: IIntegrationCardProps = {
  name: 'Forms / Implementations',
  url: '/data/pixel/implementations',
};

const apiKeysIntegration: IIntegrationCardProps = {
  name: 'API Keys',
  url: '/settings/integrations/api-keys',
};

const iMISIntegration: IIntegrationCardProps = {
  logo: iMISLogo,
  name: 'iMIS',
  url: '/settings/integrations/imis',
};

const BlackbaudRaisersEdgeIntegration: IIntegrationCardProps = {
  logo: blackbaudLogo,
  name: "Blackbaud Raiser's Edge NXT",
  url: '/settings/integrations/raisers-edge',
};

/**
 * A Page Component that renders information on Feathr's available integration services and Cards that link to internal and external integrations
 */
function IntegrationsDashboard(): JSX.Element {
  const account = useAccount();
  const { t } = useTranslation();
  const [localIntegrations, setLocalIntegrations] = useState<IIntegrationCardProps[]>([
    metaIntegration,
  ]);

  const { hasImis, hasBlackbaudRaisersEdge } = useFlags();

  const { isPlatformAdvanced, isPlatformEnterprise } = account;
  const hasDirectIntegrations = isPlatformAdvanced || isPlatformEnterprise;

  const showImis = hasDirectIntegrations || hasImis;
  const showBlackbaudRaisersEdge = hasDirectIntegrations || hasBlackbaudRaisersEdge;
  const showGoogleAds = useGoogleAdsPermissions({ requireConnectedCustomer: false });

  const isImisConflicting = account.conflictingIntegrations.includes(EIntegrationTypes.Imis);
  const isBlackbaudRaisersEdgeConflicting = account.conflictingIntegrations.includes(
    EIntegrationTypes.BlackbaudRaisersEdge,
  );

  useEffect((): void => {
    if (account.hasPixelImplementation) {
      setLocalIntegrations((prev) => [...prev, formImplementation]);
    }
    if (account.isFalcon) {
      setLocalIntegrations((prev) => [...prev, apiKeysIntegration]);
    }
  }, [account.isFalcon, account.hasPixelImplementation]);

  return (
    <Page title={t('Integrations')}>
      <Section
        className={styles.container}
        contentClassName={styles.grid}
        description={<p>{t('The local integration services within the Feathr app.')}</p>}
        title={t('Feathr Integrations')}
      >
        {localIntegrations.map((integration) => (
          <IntegrationsCard item={integration} key={integration.name} />
        ))}
        <IntegrationsCard
          disabled={!showImis || isImisConflicting}
          item={iMISIntegration}
          key={iMISIntegration.name}
          tooltip={
            (!showImis &&
              t('This integration is not available with your current Feathr license.')) ||
            (isImisConflicting &&
              t(
                "You have a conflicting integration. You must disconnect from Blackbaud Raiser's Edge NXT to be able to connect to iMIS.",
              ))
          }
        />
        {/* TODO: remove this when Blackbaud Raiser's Edge NXT is available. */}
        {showBlackbaudRaisersEdge && (
          <IntegrationsCard
            disabled={!showBlackbaudRaisersEdge || isBlackbaudRaisersEdgeConflicting}
            item={BlackbaudRaisersEdgeIntegration}
            tooltip={
              (!showBlackbaudRaisersEdge &&
                t('This integration is not available with your current Feathr license.')) ||
              (isBlackbaudRaisersEdgeConflicting &&
                t(
                  "You have a conflicting integration. You must disconnect from iMIS to be able to connect to Blackbaud Raiser's Edge NXT.",
                ))
            }
          />
        )}
        {/* TODO: remove this flag when Google Ads is available */}
        {showGoogleAds && (
          <IntegrationsCard
            disabled={!showGoogleAds}
            item={googleIntegration}
            key={googleIntegration.name}
            tooltip={
              !showGoogleAds &&
              t('This integration is not available with your current Feathr license.')
            }
          />
        )}
      </Section>
      <Section
        className={styles.container}
        contentClassName={styles.grid}
        description={t(
          'Feathr integrates with all these apps (and many others) via an integrations tool called Zapier. Click on the apps to visit their Zapier pages for more detail. You can also access help content through the links for each integration.',
        )}
        title={t('Third-Party Integrations')}
      >
        {zapierIntegrations.map((integration) => (
          <IntegrationsCard externalLink={true} item={integration} key={integration.name} />
        ))}
        <IntegrationsCard externalLink={true} item={feathrZapierIntegration} />
      </Section>
    </Page>
  );
}

export default observer(IntegrationsDashboard);
