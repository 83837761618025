import {
  faAnalytics,
  faArrowRotateLeft,
  faArrowsRotate,
  faArrowsUpDownLeftRight,
  faBoxArchive,
  faBrowser,
  faCodeMerge,
  faCopy,
  faEdit,
  faEnvelope,
  faEye,
  faFileDownload,
  faInboxIn,
  faListCheck,
  faPencil,
  faPlus,
  faSliders,
  faSortAlphaDown,
  faSortAlphaUpAlt,
  faSortNumericDown,
  faSortNumericUpAlt,
  faTrashAlt,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faCircleStop } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';

// Create a mapping so we can utilize consistent actions across the app
const actionIconMap = {
  add: faPlus,
  analytics: faAnalytics,
  archive: faBoxArchive,
  cancel: faXmark,
  clone: faCopy,
  customize: faSliders,
  delete: faTrashAlt,
  download: faFileDownload,
  edit: faPencil,
  email: faEnvelope,
  export: faFileDownload,
  manage: faListCheck,
  merge: faCodeMerge,
  move: faArrowsUpDownLeftRight,
  recalculate: faArrowsRotate,
  remove: faXmark,
  rename: faEdit,
  resend: faInboxIn,
  return: faArrowRotateLeft,
  revoke: faXmark,
  send: faInboxIn,
  shortLinks: faListCheck,
  stop: faCircleStop,
  template: faBrowser,
  view: faEye,
};

const sortIconMap = {
  stringAsc: faSortAlphaDown,
  stringDesc: faSortAlphaUpAlt,
  numberAsc: faSortNumericDown,
  numberDesc: faSortNumericUpAlt,
};

const allIcons = { ...actionIconMap, ...sortIconMap };

type TAction = keyof typeof actionIconMap;
type TSort = keyof typeof sortIconMap;

/*
 * Return the icon component for the given action. This will make a find and replace
 * when we convert to the Icon component much easier and reduce the number if imports
 * in the file the icon is added.
 */
export function getIconForAction(icon: TAction | TSort): JSX.Element {
  return <FontAwesomeIcon icon={allIcons[icon]} />;
}
