import { useCallback, useEffect, useState } from 'react';

interface IUsePreloadImageResult {
  imgSrc: string;
  isLoading: boolean;
  reload: () => void;
}

function usePreloadImage(src: string | undefined): IUsePreloadImageResult {
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [cacheBustedSrc, setCacheBustedSrc] = useState<string>(''); // Store the modified image URL
  const [reloadKey, setReloadKey] = useState(0); // Key to trigger reloads (incremented on each reload)

  // Function to generate the cachebuster URL based on current date and time
  const getCacheBustedUrl = (url: string): string => {
    const timestamp = Date.now(); // Use the current timestamp as the cachebuster
    return url.length ? `${url}?cb=${timestamp}` : '';
  };

  const reload = useCallback(() => {
    setReloadKey((prevKey) => prevKey + 1); // Increment the reload key to trigger a new cachebuster
  }, []);

  useEffect(() => {
    if (!src) {
      return;
    } // Prevent running effect if no src is provided

    const img = new Image(); // Create a new Image object
    const bustedSrc = getCacheBustedUrl(src); // Append cachebuster to the URL
    setCacheBustedSrc(bustedSrc); // Update the image source with the cachebuster

    setIsLoading(true); // Set loading state to true when starting the reload

    // Set image source and stop loading when the image is fully loaded
    img.src = bustedSrc;
    img.onload = (): void => {
      setIsLoading(false);
    };

    img.onerror = (): void => {
      // eslint-disable-next-line no-console
      console.error(`Failed to load image: ${bustedSrc}`);
      setIsLoading(false); // Stop loading in case of error
    };
  }, [src, reloadKey]); // Re-run effect whenever the source or reloadKey changes

  return { isLoading, imgSrc: cacheBustedSrc, reload };
}

export default usePreloadImage;
