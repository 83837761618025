import type { IObservableArray } from 'mobx';
import { computed, makeObservable } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type {
  Attributes,
  DeepObservable,
  IBaseAttributes,
  IListOptions,
  IListParams,
  IRachisMessage,
  ListResponse,
  TConstraints,
} from '@feathr/rachis';
import { Collection, DisplayModel, isWretchError, wretch } from '@feathr/rachis';

import type { IBannersnackAttributes, IBannersnackClass } from './bannersnack';
import type { IMergeField } from './campaigns';
import type { CustomField } from './custom_fields';
import { FieldCollection } from './custom_fields';
import type { IStats } from './stats';

export enum TemplateClass {
  Email = 'Template.HtmlTemplate.EmailTemplate',
  ReferralEmail = 'Template.HtmlTemplate.EmailTemplate.ReferralEmailTemplate',
  Page = 'Template.HtmlTemplate.PageTemplate',
  ReferralPage = 'Template.HtmlTemplate.PageTemplate.ReferralPageTemplate',
  Banner = 'Template.HtmlTemplate.PageTemplate.BannerTemplate',
  ReferralBanner = 'Template.HtmlTemplate.PageTemplate.ReferralBannerTemplate',
  LandingPage = 'Template.HtmlTemplate.PageTemplate.LandingPageTemplate',
  Link = 'Template.LinkTemplate',
  PinpointEmail = 'Template.HtmlTemplate.PinpointEmailTemplate',
}

export enum RecipientType {
  Person = 'Person',
  Partner = 'Partner',
}

interface ITemplateMergemap extends Record<string, unknown> {
  mergemap: Record<string, string>;
}

interface ITemplateEditorURLResponse extends Record<string, unknown> {
  url: string;
}

interface ITemplatePreviewPostResponse extends Record<string, unknown> {
  preview_url: string;
}

export const defaultProjectMergeFields = [
  {
    type: 'tag',
    name: 'Project name',
    value: '@EVENT_NAME@',
  },
  {
    type: 'tag',
    name: 'Project start date',
    value: '@EVENT_DATE_START@',
  },
  {
    type: 'tag',
    name: 'Project end date',
    value: '@EVENT_DATE_END@',
  },
  {
    type: 'tag',
    name: 'Project location',
    value: '@EVENT_LOCATION@',
  },
  {
    type: 'link',
    name: 'Project link',
    value: '@EVENT_URL@',
  },
  {
    type: 'link',
    name: 'Project registration link',
    value: '@EVENT_REG_URL@',
  },
  {
    type: 'content',
    name: 'Project logo image',
    value: '@EVENT_LOGO_IMG@',
  },
  {
    type: 'tag',
    name: 'Project logo URL',
    value: '@EVENT_LOGO_URL@',
  },
];

export const defaultPersonMergeFields = [
  {
    type: 'tag',
    name: 'Name',
    value: '@PERSON_NAME@',
  },
  {
    type: 'tag',
    name: 'Primary email',
    value: '@PERSON_EMAIL@',
  },
];

export const defaultPartnerMergeFields = [
  {
    type: 'tag',
    name: 'Partner name',
    value: '@PARTNER_NAME@',
  },
  {
    type: 'content',
    name: 'Partner logo image',
    value: '@PARTNER_LOGO_IMG@',
  },
  {
    type: 'tag',
    name: 'Partner logo url',
    value: '@PARTNER_LOGO_URL@',
  },
  {
    type: 'tag',
    name: 'Partner email',
    value: '@PARTNER_EMAIL@',
  },
  {
    type: 'tag',
    name: 'Partner external id',
    value: '@PARTNER_EXTERNAL_ID@',
  },
  {
    type: 'link',
    name: 'Partner website',
    value: '@PARTNER_WEBSITE@',
  },
  {
    type: 'link',
    name: 'Partner dashboard link',
    value: '@PARTNER_DASHBOARD@',
  },
  {
    type: 'tag',
    name: 'Partner description',
    value: '@PARTNER_DESCRIPTION@',
  },
  {
    type: 'tag',
    name: 'Download leads (CSV)',
    value: '@PARTNER_LEADS_URL@',
  },
];

export const participantPageUrlField = {
  type: 'link',
  name: 'Partner Page URL',
  value: '@CAMPAIGN.PARTICIPANT.PAGE_URL@',
};

export const referralPageUrlField = {
  type: 'tag',
  name: 'Referral Page URL',
  value: '@CAMPAIGN.PARTICIPANT.PAGE_URL@',
};

export const campaignDestinationUrlField = {
  type: 'link',
  name: 'Campaign Destination URL',
  value: '@CAMPAIGN.DESTINATION_URL@',
};

export const pinpointRecipientMergeFields = [
  { type: 'tag', name: 'Primary email', value: '{{Attributes.Email}}' },
  {
    type: 'tag',
    name: 'Name',
    value: '{{User.UserAttributes.name}}',
    defaultPath: 'name',
  },
  {
    type: 'tag',
    name: 'First name',
    value: '{{User.UserAttributes.first_name}}',
    defaultPath: 'first_name',
  },
  {
    type: 'tag',
    name: 'Last name',
    value: '{{User.UserAttributes.last_name}}',
    defaultPath: 'last_name',
  },
];

export const pinpointPersonMergeFields = [
  {
    type: 'tag',
    name: 'Company',
    value: '{{User.UserAttributes.companies}}',
    defaultPath: 'companies',
  },
  {
    type: 'tag',
    name: 'Occupation',
    value: '{{User.UserAttributes.occupation}}',
    defaultPath: 'occupation',
  },
];

export const pinpointPartnerMergeFields = [
  {
    type: 'content',
    name: 'Partner logo image',
    value: '{{User.UserAttributes.logo_img}}',
    defaultPath: 'logo_img',
  },
  {
    type: 'tag',
    name: 'Partner logo url',
    value: '{{User.UserAttributes.logo_url}}',
    defaultPath: 'logo_url',
  },
  {
    type: 'tag',
    name: 'Partner external id',
    value: '{{User.UserAttributes.external_id}}',
    defaultPath: 'external_id',
  },
  {
    type: 'link',
    name: 'Partner website',
    value: '{{User.UserAttributes.website}}',
    defaultPath: 'website',
  },
  {
    type: 'link',
    name: 'Partner dashboard link',
    value: '{{User.UserAttributes.dashboard_url}}',
    defaultPath: 'dashboard_url',
  },
  {
    type: 'tag',
    name: 'Partner description',
    value: '{{User.UserAttributes.description}}',
    defaultPath: 'description',
  },
  {
    type: 'tag',
    name: 'Number of leads',
    value: '{{User.UserAttributes.leads}}',
  },
  {
    type: 'tag',
    name: 'Download leads (CSV)',
    value: '{{User.UserAttributes.leads_url}}',
  },
];

export enum DynamicContentClass {
  CountdownTimer = 'DynamicContent.CountdownTimer',
  Form = 'DynamicContent.Form',
}

export interface IFormField {
  tag: string;
  name: string;
  placeholder: string;
  label: string;
  type_: string;
  required: boolean;
}

export interface IDynamicContent {
  macro_prefix: string;
  name: string;
  content_index: number;
  _cls: DynamicContentClass;

  font?: string;
  background_color?: string;
  title_color?: string;
  shortlink_color?: string;
  stat_color?: string;
  show_stats?: boolean;
  width?: number;

  color?: string;
  action?: 'update' | 'custom';
  onsubmit?: string;
  submit?: string;
  title?: string;
  subtitle?: string;
  complete?: string;
  fields?: IFormField[];

  frames?: number;
  time?: string;
}

export interface IMetaTags {
  description: string;
  author: string;
  og_title: string;
  og_type: string;
  og_image: string;
  og_image_height: number;
  og_image_width: number;
  twitter_site: string;
  twitter_creator: string;
}

export interface ITemplateDashboardConfig {
  title?: string;
}

export type TTemplateParentKind = 'account' | 'campaign' | 'event';

export interface ITemplate extends IBaseAttributes, IBannersnackAttributes {
  _cls: TemplateClass;
  account?: string;
  /** Gets set to true after calling <pk>/create-banner and bannersnack generated the banner */
  bannersnack_enabled?: boolean;
  /** Writeonly */
  campaign_id?: string;
  event?: string | null;
  /** Writeonly */
  event_id?: string | null;
  parent: string;
  parent_kind: TTemplateParentKind;
  short_code: string;
  name?: string;
  default: boolean;
  read_only: boolean;
  source_id?: string;
  thumbnail_url: string;
  stats: IStats;
  content: {
    _cls: string;
    /*
     * When unparsed, the json object contains a "page" attribute that
     * we dereference for template json.
     */
    json: string;
  };
  metatags: IMetaTags;
  dynamic_content: IDynamicContent[];
  used_mergefields: string[];
  mergefield_defaults: {
    User: {
      UserAttributes: Record<string, string>;
    };
  };
  dashboard_config: ITemplateDashboardConfig;
  date_last_modified: string;
  date_published: string;
  extra_header_html: string;
  extra_footer_html: string;
  width?: number;
  height?: number;
  /** The preview text displayed after the subject line in the email client */
  preview_text: string;
}

export interface ILinkTemplate {
  readonly id?: string;
  _cls: TemplateClass.Link;
  to: string;
  thumbnail_url: string;
  title: string;
}

export interface ISendOptions {
  email: string;
  from_: string;
  from_name: string;
}

export interface IPreviewBody extends ISendOptions {
  partner_id?: string;
  person_id?: string;
  campaign_id?: string;
  invites_campaign_id?: string;
  event_id?: string | null;
  template: ITemplate;
  send?: true;
  creative_id?: string;
}

export function templateTypeLabel(type: TemplateClass): string {
  const typeMap: Record<TemplateClass, string> = {
    [TemplateClass.Email]: 'Email',
    [TemplateClass.ReferralEmail]: 'Invite email',
    [TemplateClass.Page]: 'Page',
    [TemplateClass.LandingPage]: 'Landing page',
    [TemplateClass.ReferralPage]: 'Invite page',
    [TemplateClass.Banner]: 'Banner',
    [TemplateClass.ReferralBanner]: 'Invite Banner',
    [TemplateClass.Link]: 'Link',
    [TemplateClass.PinpointEmail]: 'Email',
  };
  return typeMap[type];
}

interface ICloneProps {
  campaign_id?: string;
  event_id?: string | null;
  /** Required, even though the interface requires it to be optional. */
  _cls?: string;
}

export class Template extends DisplayModel<ITemplate> implements IBannersnackClass {
  public readonly className = 'Template';

  public get constraints(): TConstraints<ITemplate> {
    return {
      name: {
        presence: {
          allowEmpty: false,
        },
      },
      subject: {
        presence: {
          allowEmpty: false,
        },
      },
      short_code: {
        presence: {
          allowEmpty: false,
          message: '^URL Template cannot be blank.',
        },
        length: {
          minimum: 3,
          tooShort: '^URL Template must have at least 3 characters.',
        },
        format: (...args: any[]) => {
          // args = value, attributes, attributeName, options, constraints
          const _cls = args[3].model.get('_cls');
          if (_cls === TemplateClass.LandingPage) {
            return {
              pattern: '^[a-zA-Z0-9_-]+$',
              message: '^Template URL can only contain letters, numbers, dashes, and underscores.',
            };
          }
          // Campaigns other than Landing Pages can have special characters, e.g. merge tags
          return undefined;
        },
        async: {
          fn: async (value: string | undefined, model: Template): Promise<string[] | undefined> => {
            if (!value) {
              return;
            }
            if (!model.collection) {
              return [
                '^Model does not have a collection and therefore validity cannot be determined.',
              ];
            }
            const response = await wretch(
              `${model.collection.url()}${this.id}/validate_short_code`,
              {
                method: 'POST',
                headers: model.collection.getHeaders(),
                body: JSON.stringify({ short_code: value }),
              },
            );
            if (isWretchError(response)) {
              throw response.error;
            }
            const validShortCode = response.data ? response.data['is_valid_short_code'] : false;
            if (!validShortCode) {
              return ['^Short code must be unique.'];
            }
            return undefined;
          },
        },
      },
    };
  }

  constructor(initialAttributes: Partial<ITemplate> = {}) {
    super(initialAttributes);

    makeObservable(this);
  }

  /**
   * Clone a template.
   *
   * @param _cls: string (required)
   * @param campaign_id: string (required)
   * @param event_id: string (required)
   */
  public async clone(data: ICloneProps): Promise<this> {
    return super.clone(data as Partial<Attributes<this>>);
  }

  public async preview(
    campaignId?: string,
    invitesCampaignId?: string,
    partnerId?: string,
    personId?: string,
  ): Promise<ITemplatePreviewPostResponse> {
    this.assertCollection(this.collection);

    // Default and account templates do not include an event id.
    const path = window.location.pathname.split('/');
    const eventId = path[3];

    const body: Partial<IPreviewBody> = {
      partner_id: partnerId,
      campaign_id: campaignId,
      invites_campaign_id: invitesCampaignId,
      person_id: personId,
      event_id: this.get('event') || eventId,
      template: this.toJS(),
    };
    const response = await wretch<ITemplatePreviewPostResponse>(
      `${this.collection.url()}${this.id}/preview`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.collection.getHeaders(),
      },
    );
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public async mergemap(
    campaignId?: string,
    invitesCampaignId?: string,
    partnerId?: string,
    personId?: string,
  ): Promise<ITemplateMergemap> {
    this.assertCollection(this.collection);
    // Default and account templates do not include an event id.
    const path = window.location.pathname.split('/');
    const eventId = path[3];

    const params = new URLSearchParams({ event_id: this.get('event') ?? eventId });
    if (!!partnerId) {
      params.set('partner_id', partnerId);
    }
    if (!!campaignId) {
      params.set('campaign_id', campaignId);
    }
    if (!!invitesCampaignId) {
      params.set('invites_campaign_id', invitesCampaignId);
    }
    if (!!personId) {
      params.set('person_id', personId);
    }
    const response = await wretch<ITemplateMergemap>(
      `${this.collection.url()}${this.id}/mergemap?${params.toString()}`,
      {
        method: 'GET',
        headers: this.collection.getHeaders(),
      },
    );
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public async send(
    sendOptions: ISendOptions,
    campaignId?: string,
    invitesCampaignId?: string,
    partnerId?: string,
    personId?: string,
  ): Promise<IRachisMessage> {
    this.assertCollection(this.collection);
    // Default and account templates do not include an event id.
    const path = window.location.pathname.split('/');
    const eventId = path[3];

    const body: Partial<IPreviewBody> = {
      partner_id: partnerId,
      campaign_id: campaignId,
      invites_campaign_id: invitesCampaignId,
      person_id: personId,
      event_id: this.get('event') || eventId,
      template: this.toJS(),
      ...sendOptions,
    };
    const response = await wretch<IRachisMessage>(`${this.collection.url()}${this.id}/send`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.collection.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public async editorURL(): Promise<string> {
    this.assertCollection(this.collection);
    const response = await wretch<ITemplateEditorURLResponse>(
      `${this.collection.url()}${this.id}/bannereditor`,
      {
        method: 'GET',
        headers: this.collection.getHeaders(),
      },
    );
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data.url;
  }

  public async sync(timestamp: number): Promise<void> {
    this.assertCollection(this.collection);
    this.isUpdating = true;
    const response = await wretch<ITemplate>(
      `${this.collection.url()}${this.id}/sync?ts=${timestamp}`,
      {
        method: 'GET',
        headers: this.collection.getHeaders(),
      },
    );
    if (isWretchError(response)) {
      throw response.error;
    }
    if (!this.get('banner_hash')) {
      this.set({ banner_hash: response.data.banner_hash });
    }
    this.set({
      banner_urls: response.data.banner_urls,
      height: response.data.height,
      name: response.data.name,
      width: response.data.width,
    });
    this.isUpdating = false;
  }

  public async createBanner(
    campaignId: string,
    partnerId: string,
    creativeId?: string,
  ): Promise<IRachisMessage> {
    this.assertCollection(this.collection);
    const body: Partial<IPreviewBody> = {
      partner_id: partnerId,
      campaign_id: campaignId,
      event_id: this.get('event'),
      template: this.toJS(),
    };
    if (creativeId) {
      body.creative_id = creativeId;
    }
    const response = await wretch<IRachisMessage>(
      `${this.collection.url()}${this.id}/create-banner`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: this.collection.getHeaders(),
      },
    );
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  public get isBanner(): boolean {
    return [TemplateClass.Banner, TemplateClass.ReferralBanner].includes(this.get('_cls'));
  }

  public shouldUsePlaceholder(): boolean {
    return !!this.get('bannersnack_enabled') && !this.get('banner_hash');
  }

  public getItemUrl(pathSuffix?: string): string {
    // Default and account templates do not include an event id.
    const path = window.location.pathname.split('/');
    const eventId = path[3];

    return this.get('parent_kind') === 'campaign'
      ? concatPath(
          `/projects/${this.get('event')}/campaigns/${this.get('parent')}/templates/${this.id}`,
          pathSuffix,
        )
      : concatPath(
          `/projects/${this.get('event') ?? eventId}/content/templates/${this.id}`,
          pathSuffix,
        );
  }

  public getTypeLabel(): string {
    const cls = this.get('_cls');
    return cls ? templateTypeLabel(cls) : 'Unknown';
  }

  public getDynamicContentMacro(dc: { _cls: DynamicContentClass; content_index: number }): string {
    switch (dc._cls) {
      case DynamicContentClass.CountdownTimer:
        return `@COUNTDOWN_TIMER_${dc.content_index}@`;

      case DynamicContentClass.Form:
        return `@FORM_${dc.content_index}@`;

      default:
        return `DYNAMIC_CONTENT_${dc.content_index}@`;
    }
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Template';
  }

  public getMergefields(
    customFields: IObservableArray<CustomField>,
    isPartnerMessageCampaign?: boolean,
  ): IMergeField[] {
    let mergefields = this.getTemplateClassMergeFields();
    const isDefault = this.get('default');
    const isPinpoint = this.get('_cls') === TemplateClass.PinpointEmail;
    if (isPartnerMessageCampaign) {
      const pinpointPartnerFields = customFields
        .filter(
          (f) =>
            [FieldCollection.Project, FieldCollection.Partner].includes(f.get('collection')) &&
            f.get('data_type') !== 'list',
        )
        .sort((a, b) =>
          a.get('u_key', '').localeCompare(b.get('u_key', ''), undefined, { sensitivity: 'base' }),
        )
        .map((f) => ({
          type: 'tag',
          name: `${f.get('collection')} ${f.get('u_key', '')}`,
          value: `{{User.UserAttributes.cust_${
            f.get('u_key', '').toLowerCase().replace(/\W/g, '_') || ''
          }}}`,
          defaultPath: `cust_${f.get('u_key', '').toLowerCase().replace(/\W/g, '_')}`,
        }));
      mergefields = [...pinpointPartnerMergeFields, ...pinpointPartnerFields];
    } else if (!isDefault && !isPinpoint) {
      const partnerParams = customFields
        .filter((p) => p.get('collection') === FieldCollection.Partner)
        .map((p) => ({
          type: 'tag',
          name: `(Partner) ${(p.get('u_key', '') || '').toUpperCase()}`,
          value: `@PARTNER.${(p.get('u_key', '') || '').toUpperCase()}@`,
          isCustom: true,
        }));
      mergefields = [...mergefields, ...partnerParams];
    } else if (!isDefault && isPinpoint) {
      const recipientCustomFields = customFields
        // Exclude list fields due to incompatibility with pinpoint 100 char limit workaround
        .filter(
          (f) =>
            [FieldCollection.Person].includes(f.get('collection')) && f.get('data_type') !== 'list',
        )
        // Case-insensitive alphabetical sort by user-friendly field name
        .sort((a, b) =>
          a.get('u_key', '').localeCompare(b.get('u_key', ''), undefined, { sensitivity: 'base' }),
        )
        // Convert fields to merge tag format
        .map((f) => ({
          type: 'tag',
          name: `${f.get('u_key', '')}`,
          value: `{{User.UserAttributes.cust_${
            f.get('u_key', '').toLowerCase().replace(/\W/g, '_') || ''
          }}}`,
          defaultPath: `cust_${f.get('u_key', '').toLowerCase().replace(/\W/g, '_')}`,
        }))
        // Merge duplicate tags, set prefix to 'Recipient' if valid for both persons and partners
        .reduce((prev, cur) => {
          if (!prev.length || prev[prev.length - 1].value !== cur.value) {
            prev.push(cur);
          } else if (prev[prev.length - 1].name.split(' ')[0] !== cur.name.split(' ')[0]) {
            prev[prev.length - 1].name = [
              'Recipient',
              ...prev[prev.length - 1].name.split(' ').slice(1),
            ].join(' ');
          }
          return prev;
        }, Array<IMergeField>());
      mergefields = [...mergefields, ...recipientCustomFields];
    }
    const dynamicContent = this.get('dynamic_content');
    if (dynamicContent) {
      const dynamicContentFields = dynamicContent.map((dc: IDynamicContent) => ({
        type: 'content',
        name: dc.name,
        value: this.getDynamicContentMacro(dc),
      }));
      mergefields = [...mergefields, ...dynamicContentFields];
    }

    return mergefields;
  }

  private getTemplateClassMergeFields(): IMergeField[] {
    switch (this.get('_cls')) {
      case TemplateClass.Banner:
        return [
          ...defaultProjectMergeFields,
          ...defaultPartnerMergeFields,
          campaignDestinationUrlField,
          referralPageUrlField,
        ];

      case TemplateClass.ReferralEmail:

      case TemplateClass.Page:
        return [
          ...defaultProjectMergeFields,
          ...defaultPartnerMergeFields,
          participantPageUrlField,
        ];

      case TemplateClass.Email:

      case TemplateClass.LandingPage:
        return defaultProjectMergeFields;

      case TemplateClass.PinpointEmail:
        return [...pinpointRecipientMergeFields, ...pinpointPersonMergeFields];
    }
    return [...defaultProjectMergeFields, ...defaultPartnerMergeFields];
  }

  public getRowDisplayConditions(): never[] {
    return [];
  }

  /**
   * Returns value of attribute
   * @param attribute
   */
  public get<K extends keyof ITemplate & string>(attribute: K): DeepObservable<ITemplate[K]>;
  /**
   * Returns value of attribute; defaultValue when value is not set
   * Note: defaultValue is not returned when value is falsey, but when value is null or undefined
   *
   * @param attribute
   * @param defaultValue
   */
  public get<K extends keyof ITemplate & string>(
    attribute: K,
    defaultValue: Exclude<ITemplate[K], undefined>,
  ): DeepObservable<Exclude<ITemplate[K], undefined>>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get<K extends keyof ITemplate & string>(attribute: K, defaultValue?: any): any {
    const value = super.get(attribute, defaultValue);
    // TODO: Remove hack to handle old templates with json that are objects instead of strings
    if (attribute === 'content' && typeof value.json !== 'string') {
      return { ...value, json: JSON.stringify(value.json) };
    }
    return value;
  }
}

// TODO: This is probably not needed once we are querying templates with ViewMixin
export interface ITemplateListParams extends IListParams<ITemplate> {
  defaults: 0 | 1;
}

export class Templates extends Collection<Template> {
  public async clone(
    id: string,
    data: Pick<ITemplate, 'campaign_id' | 'event_id' | '_cls'>,
  ): Promise<Template> {
    return super.clone(id, data);
  }

  public getModel(attributes = {}): Template {
    return new Template(attributes);
  }

  public getClassName(): string {
    return 'templates';
  }

  /**
   * @param listId Use the useId() hook to generate a unique listID.
   */
  public list(
    params: Partial<ITemplateListParams>,
    options: Partial<IListOptions> = {},
    listId?: string,
  ): ListResponse<Template> {
    return super.list(params, options, listId);
  }
}
