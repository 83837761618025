import type { TFunction } from 'i18next';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type { Domain, EPinpointRequestStatus } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import {
  Button,
  Chip,
  ContextMenu,
  EMouseLeaveDelay,
  TableColumnHeader,
  Tooltip,
} from '@feathr/components';
import { useFlags } from '@feathr/extender/state';
import { getIconForAction } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IRow {
  original: Domain;
}

type TStatusRecord = Record<EPinpointRequestStatus | 'N/A', string>;

const statusToColorMap: TStatusRecord = {
  Pending: 'yellow',
  Success: 'green',
  Failed: 'red',
  TemporaryFailure: 'orange',
  NotStarted: 'default',
  /*
   * Adding N/A as default for Domains that have
   * not initiated the Email Domain verification process since
   * they won't have DKIM or MX records with a status
   */
  'N/A': 'default',
};

const statusToLabelMap: TStatusRecord = {
  Pending: 'Pending',
  Success: 'Ready',
  Failed: 'Failed',
  TemporaryFailure: 'Erroring',
  NotStarted: 'N/A',
  'N/A': 'N/A',
};

function OptionsCell({ original }: IRow): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();

  function handleClickEdit(): void {
    history.push(original.getItemUrl('edit'));
  }

  return (
    <>
      <ContextMenu buttonType={'icon'} name={'domain-options'} position={'left-start'}>
        <ContextMenu.Item
          name={'edit-role'}
          onClick={handleClickEdit}
          prefix={getIconForAction('edit')}
        >
          {t('Edit')}
        </ContextMenu.Item>
      </ContextMenu>
    </>
  );
}

function EmailStatusCell({ original }: IRow): JSX.Element {
  const flags = useFlags();
  const { t } = useTranslation();
  const history = useHistory();

  function handleClickRefresh(): void {
    history.push(original.getItemUrl());
  }

  const status = original.emailSendingStatus(flags.bypassMX);
  const tooltipMap = {
    Success: t('This domain, and any sender from it, are ready to be used in email campaigns.'),
    Failed: (
      <Trans t={t}>
        Add CNAME and mail records for this domain to your DNS host and then{' '}
        <Button onClick={handleClickRefresh} type={'link'}>
          refresh it
        </Button>
        .
      </Trans>
    ),
    'N/A': t("This domain isn't setup to be used for email."),
  };
  return (
    <Chip
      isLoading={original.isPending}
      mouseLeaveDelay={EMouseLeaveDelay.Link}
      theme={statusToColorMap[status]}
      tooltip={tooltipMap[status]}
    >
      {statusToLabelMap[status]}
    </Chip>
  );
}

function domainsColumns(t: TFunction): Array<IColumn<Domain>> {
  return [
    {
      id: 'content_domain',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Content Domain'),
      }),
      headerClassName: tableStyles.sort,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return (
                <Tooltip title={t('View details')}>
                  <Button href={original.getItemUrl()} type={'link'}>
                    {original.get('content_domain')}
                  </Button>
                </Tooltip>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'email_domain',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Email Domain'),
      }),
      headerClassName: tableStyles.sort,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return (
                <Tooltip title={t('View details')}>
                  <Button href={original.getItemUrl()} type={'link'}>
                    {original.get('email_domain')}
                  </Button>
                </Tooltip>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'content_status',
      Header: TableColumnHeader({
        title: t('Content Serving Status'),
        tooltip: t(
          "This domain's status for serving landing pages, partner dashboards, and invite pages.",
        ),
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      width: 110,
      Cell({ original }: IRow): JSX.Element {
        const status = original.get('redirect').is_verified ? 'Success' : 'Pending';
        return (
          <Chip
            isLoading={original.isPending}
            theme={statusToColorMap[status]}
            tooltip={
              status === 'Success'
                ? t('This domain can be used to serve pages, invite pages, and partner dashboards.')
                : t(
                    'Add CNAME records for this domain to your DNS host to use it for serving content.',
                  )
            }
          >
            {statusToLabelMap[status]}
          </Chip>
        );
      },
    },
    {
      id: 'email_status',
      Header: TableColumnHeader({
        title: t('Email Sending Status'),
        tooltip: t("This domain's status for sending emails from Feathr."),
      }),
      headerClassName: tableStyles.tooltip,
      sortable: false,
      width: 110,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return <EmailStatusCell original={original} />;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'options',
      Header: TableColumnHeader({
        title: t('Options'),
      }),
      width: 80,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return <OptionsCell original={original} />;
            }}
          </Observer>
        );
      },
    },
  ];
}

export default domainsColumns;
